<template>
    <div class="flex-column flex-align-center wxapp-openScheme-content">
        <div class="flex-column flex-align-center wxapp-openScheme-box">
            <div class="flex-row-center flex-align-center img-bg">
                <img src="../../assets/weixin-icon.png"/>
            </div>
            <div class="img-text">正在跳转微信...</div>
        </div>
        <div class="wxapp-openScheme-button">
            <div class="handle-wx" @click="openWx()">打开微信小程序</div>
        </div>
    </div>
</template>
<script>
    import { getUrlScheme } from "../../api/wxappApi.js";
    export default{
        data(){
            return{
                openUrl:'',
                openStatus:''
            }
        },
        mounted() {
            let code = this.$route.params.code
            this.getUrlSchemeData(code)
            // console.log(">>>>>>>>code",code)
            // this.openUrl = 'weixin://dl/business/?t='+code
            // location.href = this.openUrl
        },
        methods: {
            getUrlSchemeData(code){
                getUrlScheme({code:code}).then(res=>{
                    let data = res.data
                    if(data.status==1){
                        this.openStatus = data.status
                        this.openUrl = data.openlink
                        location.href = this.openUrl
                    }
                })
            },
            openWx(){
                if(this.openStatus==1){
                  location.href = this.openUrl
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
.wxapp-openScheme{
    &-content{
        margin-top: 180px;
    }
    &-box{
      
        .img-bg{
            width: 80px;
            height: 80px;
            background: #2DC100;
            border-radius: 50%;
            padding: 20px;
            img{
                width: 100%;
            }
        }
        .img-text{
            margin-top: 32px;
            font-size: 32px;
            font-weight: bold;
            color: #1f1f1f;
        }
    }
    &-button{
        margin-top: 120px;
        .handle-wx{
            height: 90px;
            font-size: 34px;
            color: #fff;
            line-height: 90px;
            padding: 0 50px;
            border-radius: 24px;
            background-color: #36aa13;

        }
    }
}
</style>