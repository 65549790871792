import Vue from 'vue'
import VueRouter from 'vue-router'
import openWxapp from '../views/wxapp/open'//打开微信小程序
import openScheme from '../views/wxapp/openScheme'//通过Scheme打开微信小程序

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/openWxapp',
    name: 'openWxapp',
    meta:{
      title:'打开小程序'
    },
    component: openWxapp
  }, 
  {
    path: '/:code',
    name: 'openScheme',
    meta:{
      title:'打开小程序'
    },
    component: openScheme
  }, 
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, saveTop){
    if (saveTop) {
        return saveTop;
    } else {
        return {x: 0, y: 0}
    }
}
})

export default router
