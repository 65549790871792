<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss">
.flex-row {
		display: flex;
		flex-direction: row;
	}

	.flex-row-between {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.flex-row-around {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.flex-row-center {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.flex-row-right {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.flex-align-center {
		align-items: center;
	}

	.flex-align-start {
		align-items: flex-start;
	}

	.flex-align-end {
		align-items: flex-end;
	}

	.line-row {
		width: 100%;
		height: 1px;
		background: rgba(244, 244, 244, 1);
	}
</style>
