import request from './request'

//获取微信信息
export function getWxInfo(params) {
  return request({
    url: 'data/getWx',
    method: 'get',
    params
  })
}
//获取跳转链接
export function getUrlScheme(params) {
  return request({
    url: 'data/getUrlScheme',
    method: 'get',
    params
  })
}