<template>
    <div class="wxapp-open-content">
        <div v-html="wxHtml"></div>
    </div>
</template>
<script>
import { getWxInfo } from "../../api/wxappApi.js";
export default {
    data() {
        return {
            wxHtml:''
        }
    },
    mounted() {
        this.$nextTick(() => {
            document.addEventListener("UniAppJSBridgeReady", function () { });
        });
        // this.config()
        this.tempDataParams = this.$route.query;
    },
    beforeCreate() {
        document.title = '打开微信小程序';
    },
    methods: {
        config() {
            getWxInfo({url:window.location.href}).then(res => {
                let data = res
                wx.config({
                    debug: false,
                    appId: data.data.appId,
                    timestamp: data.data.timestamp,
                    nonceStr: data.data.nonceStr,
                    signature: data.data.signature,
                    jsApiList: ['openLocation'],
                    openTagList: ['wx-open-launch-weapp']
                });
                wx.ready(function(res) { //需在用户可能点击分享按钮前就先调用
                    console.log(">>>>成功",res)
                    // alert('成功')
                })
                wx.error(function (error) {
                    alert(error.errMsg)
                    console.log(">>>失败",error)
                // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名
                });
                this.getHtml(data.data)
            })
        },
        getHtml(data) {
            let script = document.createElement('script')
            script.type = 'text/wxtag-template'
            script.text = `<button class="btn blue mt1" style="height: 50px;width: 100px;">跳转小程序</button>`
            this.wxHtml =
                `<wx-open-launch-weapp style="height: 50px;width: 100px;"
		                          id="launch-btn"
								    appid="${data.appId}"
		                            username="${data.wxId}"
		                            path="/pages/index/index.html"
		                          >${script.outerHTML}
		                        </wx-open-launch-weapp>`
            this.$nextTick(() => {
                let launchButton = document.getElementById('launch-btn')
                launchButton.addEventListener('launch', function (e) {
                    console.log('success', e)
                })

                launchButton.addEventListener('error', function (e) {
                    console.log('fail', e)
                })
            })
        }
    },
}
</script>