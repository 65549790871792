import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'amfe-flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import MintUI from 'mint-ui'

import 'mint-ui/lib/style.css'
import setting from './setting/index'
Vue.prototype.FEServer = setting.FEServer
Vue.use(MintUI)
Vue.config.productionTip = false
Vue.config.ignoredElements = [
  'wx-open-launch-weapp',
]
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
